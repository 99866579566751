<!--
  컨텐츠 영역 포함된 전체 레이아웃..
-->
<script setup>

import CommonHeader from "@/components/MainHeader.vue";

import {onMounted} from "vue";

//const isMobileMenu = ref(false)
/*
const toggleMobileMenu = (() => {
  console.log("toggleCallback......")
  if(isMobileMenu.value) isMobileMenu.value = false
  if(!isMobileMenu.value) isMobileMenu.value = true
})
*/

onMounted (() => {
  console.log("layoutContents.vue ==============================================")
})

</script>
<template>

  <CommonHeader></CommonHeader>
  <router-view></router-view>

</template>

<style scoped>

</style>