/**
 * api 공통
 */
import NetworkService from "@/api/net.service";

const NetApiServer = new NetworkService("")

/**
 * 콘텐츠 api
 * @type {{getSidoList(): Promise<AxiosResponse<any>>}}
 */
export const ApiContent = {

  /**
   * 콘텐츠 목록 가져오기
   * @returns {Promise<AxiosResponse<any>>}
   */
  getContentList(params){
    return NetApiServer.post("/api/content/contentList", params).then(res => {
      return res
    })
  },

  /**
   * 콘텐츠 상세보기
   * @param params
   * @returns {*}
   */
  getContentDetail(params) {
    return NetApiServer.post("/api/content/getContentDetail", params).then(res => {
      return res
    })
  },

  /**
   * 콘텐츠 상세정보
   */
  detailContent(params) {
    return NetApiServer.post("/api/content/detailContent", params).then(res => {
      return res
    })
  },

  /**
   * 대분류 목록
   * @param params
   */
  getFirstList() {
    return NetApiServer.post("/api/content/getFirstList").then(res => {
      return res
    })
  },

  /**
   * 중분류 목록
   * @param params
   */
  getSecondList(params) {
    return NetApiServer.post("/api/content/getSecondList", params).then(res=>{
      return res
    })
  },

  /**
   * 소분류 목록
   * @param params
   */
  getThirdList(params) {
    return NetApiServer.post("/api/content/getThirdList", params).then(res=>{
      return res
    })
  },
  /**
   * 댓글 목록 - 환자
   * @param params
   */
  getCommentListByPatient(params) {
    return NetApiServer.post("/api/content/getCommentListByPatient", params).then(res=>{
      return res
    })
  },
  /**
   * 댓글 등록
   * @param params
   */
  insertComment(params) {
    return NetApiServer.post("/api/content/insertComment", params).then(res=>{
      return res
    })
  },
  /**
   * 댓글 목록 - 의사
   * @param params
   */
  getCommentListByDoctor(params) {
    return NetApiServer.post("/api/content/getCommentListByDoctor", params).then(res => {
      return res
    })
  },
  /**
   * 댓글 수정
   * @param params
   * @returns {*}
   */
  updateComment(params) {
    return NetApiServer.post("/api/content/updateComment", params).then(res=>{
      return res
    })
  },
  /**
   * 댓글 삭제
   * @param params
   * @returns {*}
   */
  deleteComment(params) {
    return NetApiServer.post("/api/content/deleteComment", params).then(res=>{
      return res
    })
  },

  /**
   * 사용자가 해당 글 좋아요 눌렀는지 체크
    * @param params
   * @returns {*}
   */
  likeCountById(params) {
    return NetApiServer.post("/api/content/likeContentById", params).then(res=> {
      return res
    })
  },

  /**
   * 좋아요 테이블 추가
   * @param params
   * @returns {*}
   */
  insertLike(params) {
    return NetApiServer.post("/api/content/insertLike", params).then(res=>{
      return res
    })
  },

  /**
   * 좋아요 테이블 삭제
   * @param params
   * @returns {*}
   */
  deleteLike(params) {
    return NetApiServer.post("/api/content/deleteLike", params).then(res=>{
      return res
    })
  },

  /**
   * 좋아요 수 수정
    * @param params
   * @returns {*}
   */
  updateLikes(params) {
    return NetApiServer.post("/api/content/updateLikes", params).then(res=>{
      return res
    })
  },
  /**
   * 링크된 현재 컨텐츠/이전 컨텐츠 조회
   * @returns {Promise<AxiosResponse<any>>}
   */
  selLinkContentsList(params){
    return NetApiServer.post("/api/content/selLinkContentsList", params).then(res => {
      return res
    })
  },

  /**
   * 콘텐츠 목록 가져오기(메인화면)
   * @param params
   * @returns {*}
   */
  allList(params){
    return NetApiServer.post("/api/content/allList", params).then(res => {
      return res
    })
  },

  /**
   * 환자별 추천 콘텐츠 목록
   * @param params
   * @returns {*}
   */
  getContentListByUser(params) {
    return NetApiServer.post("/api/content/getContentListByUser", params).then(res=>{
      return res
    })
  }
}
