<script setup>

</script>

<template>
  <!-- footer -->
  <div class="footer">
    <div class="footer_inner">
      <div>
        <h3 class="footer_logo">
          <b>위암 환자 삶의 질 연구회</b>
          <p class="second_title" style="font-size: 1.6rem;">KOrean QUality of life in Stomach cancer patient Study group (KOQUSS) (대한위암학회 산하 연구회)</p>
          </h3>
        <address>
          <span class="addr">서울특별시 종로구 새문안로 92 광화문오피시아 1616호</span>
          <span class="tel"> 02-730-3760</span>
        </address>
      </div>

      <ul class="footer_menu">
        <router-link to="/termsInfo"><li><a href="#">서비스 이용약관</a></li></router-link>
        <router-link to="/privacyInfo"><li><a href="#">개인정보처리방침</a></li></router-link>
      </ul>
    </div>
  </div>
  <!-- // footer -->
</template>

<style scoped>
@media screen and (max-width: 767px) {
  .footer .footer_logo .second_title {
    font-size: 1rem !important;
    line-height: 1.4rem;
  }
}
</style>