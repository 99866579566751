/*
공통 function
**/

import router from "@/router";


window.onload = function() {
  /**
   * 로그인 상태 유지(isLogged) 값이 false 이면 로그아웃
   * @type {boolean|*}
   */
  let isLogged = JSON.parse(window.localStorage.getItem("userinfoKey"))?.userInfoStore?.isLoggedIn
  let sessionLogin = sessionStorage.getItem('sessionLogin')

  console.log('sessionLogin : ' + sessionLogin)

  //let cnuToken = window.localStorage.getItem("_cnu_token")
  if(isLogged == false && sessionLogin == null){
    Common.LogOut()
  }
}

export const Common = {

  fnIsEmpty (val) {
    if (!val) return true
    if (typeof val === "undefined") return true
    if (val === "") return true
    return false
  },
  // 로그아웃
  LogOut () {
    router.push("/LogOut")
  },
  // 초 -> 시간 str로 변경 (60 -> 01:00)
  secondsToTimeString (seconds) {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = (seconds % 60).toFixed(0);
    return [h, m >= 10 ? m : h ? '0' + m : m || '0', s >= 10 ? s : '0' + s]
      .filter(a => a)
      .join(':');
  },
  // 두 수의 퍼센트 구함
  getPercentage (value, defaultValue) {
    if (!value) return "-"
    if (!defaultValue) return "-"
    if (isNaN(value)) return "-"
    if (isNaN(defaultValue)) return "-"
    if (defaultValue === 0) return value.toFixed(2);
    let res = (value / defaultValue) * 100
    if (isNaN(res)) return "-"
    return res.toFixed(1)
  },
  // 3자리 콤마, 소수점 자리수 2자리
  getCommaAndFixed (val) {
    if (!val) return "-"
    if (typeof val == 'undefined') {
      return "-";
    }
    if (isNaN(val)) return "-"
    let inNum = parseFloat(val);
    if (inNum === 0) return val;
    inNum = val.toFixed(1)
    let reg = /(^[+-]?\d+)(\d{3})/;
    let n = (inNum + '');

    while (reg.test(n)) n = n.replace(reg, '$1' + ',' + '$2');
    return n;
    // ecma script 미지원..
    //return new Intl.NumberFormat("en-US").format(inNum);
  },
  // 숫자 3자리 콤
  getComma (val) {
    if (typeof val === 'undefined') {
      return "-";
    }
    if (!val) return "-"
    if (isNaN(val)) return "-"

    let inNum = parseFloat(val);
    if (inNum === 0) return val;
    let reg = /(^[+-]?\d+)(\d{3})/;
    let n = (inNum + '');

    while (reg.test(n)) n = n.replace(reg, '$1' + ',' + '$2');
    return n;
    // ecma script 미지원..
    //return new Intl.NumberFormat("en-US").format(inNum);
  },

  numPlusZero (number) {
    if (number < 10) {
      return '0' + number;
    } else {
      return number.toString();
    }
  },

  // 김** 마스킹 처리
  maskStringExceptFirst (inputString) {

    if (inputString == null || inputString == '') {
      return
    }

    // 첫 글자를 제외한 나머지 부분을 '*'로 대체
    const firstChar = inputString.charAt(0);
    const maskedPart = '*'.repeat(inputString.length - 1);
    const maskedString = firstChar + maskedPart;
    return maskedString;
  },

  // 전화번호 하이픈 - 추가 ex) 01011112222 -> 010-1111-2222
  addHyphenToPhoneNumber (phoneNumber) {

    if (phoneNumber == null || phoneNumber == '') {
      return
    }

    // 정규식을 사용하여 숫자만 남기고 나머지 문자 제거
    const numericOnly = phoneNumber.replace(/\D/g, '');

    // 정규식을 사용하여 전화번호에 하이픈(-) 추가

    let formattedPhoneNumber = null
    if(phoneNumber.length == 11){
      formattedPhoneNumber = numericOnly.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    }else if(phoneNumber.length == 10){
      formattedPhoneNumber = numericOnly.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }

    return formattedPhoneNumber;
  }
}
