import { createRouter, createWebHashHistory } from 'vue-router'

import MainPage from "@/views/MainPage.vue"

const routes = [

  {
    // 로그인
    path : "/login",
    name: 'login',
    meta : {layout: "layoutNoFooter", bodyclass: 'bg_body', title:"WECARE"},
    component: ()=> import('@/views/LogIn.vue')
  },
  {
    // 로그인 - 아이디/비밀번호 찾기
    path : '/idPwInquiry',
    name : 'idPwInquiry',
    meta : {layout : "layoutNoFooter", bodyclass: "", title:"WECARE"},
    component:()=> import('@/views/IdPwInquiry.vue')

  },
  /* {
    // 회원가입 - 회원유형 선택
    path : '/join',
    name : 'join',
    meta : {layout : 'layoutNoFooter', bodyclass: '', title: '회원가입'},
    component:() => import('@/views/JoinByType.vue')
  }, */
  {
    // 회원가입 - 의사
    path : '/joinDoctor',
    name : 'joinDoctor',
    meta : {layout : 'layoutNoFooter', bodyclass: '', title: 'WECARE'},
    component:()=> import('@/views/JoinDoctor.vue')
  },
  {
    // 약관 동의
    path: '/agreeTerm',
    name : 'agreeTerm',
    meta : {layout: 'layoutNoFooter', bodyclass: '', title: 'WECARE'},
    component:() => import('@/views/agreeTerm.vue')
  },
  {
    // 회원가입 - 환자
    path : '/joinPatient',
    alias : ['/joinPatient', '/join'],
    name : 'joinPatient',
    meta : {layout : 'layoutNoFooter', bodyclass: '', title: 'WECARE'},
    component:()=> import('@/views/JoinPatient.vue')
  },
  {
    // 메인페이지
    path: '/',
    name: 'main',
    meta: { layout : "layoutFull", bodyclass: "", title: "WECARE"},
    component: MainPage
  },
  {
    // 통합검색
    path : '/totalSearch',
    name : 'totalSearch',
    meta : {layout: 'layoutFull', bodyclass: "", title: "WECARE"},
    component:()=> import('@/views/TotalSearch.vue')
  },
  {
    //암 콘텐츠
    path: '/content',
    name: 'content',
    meta: { layout : "layoutNoFooter", bodyclass: "", title: "WECARE"},
    component: () => import('@/views/CancerContents.vue')
  },
  {
    //암 콘텐츠 상세
    path: '/detail',
    name: 'detail',
    meta: { layout : "layoutNoFooter", bodyclass: "", title: "WECARE"},
    component: () => import('@/views/CancerContDetail.vue')
  },
  {
    //설문조사
    path: '/survey',
    name: 'survey',
    meta: { layout : "layoutNoFooter", bodyclass: "", title: "WECARE"},
    component: () => import('@/views/MainSurvey.vue')
  },
  {
    //설문조사 시작
    path: '/surveyStart',
    name: 'surveyStart',
    meta: { layout : "layoutNoFooter", bodyclass: "", title: "WECARE"},
    component: () => import('@/views/SurveyDetail/SurveyStart.vue')
  },
  {
    //설문 결과
    path: '/surveyResult',
    name: 'surveyResult',
    meta: { layout : "layoutNoFooter", bodyclass: "", title: "WECARE"},
    component: () => import('@/views/SurveyResult.vue')
  },
  {
    //설문 결과 - 자가 진단
    path: '/surveyResultSelf',
    name: 'surveyResultSelf',
    meta: { layout : "layoutNoFooter", bodyclass: "", title: "WECARE"},
    component: () => import('@/views/SurveyResultSelf.vue')
  },
  {
    //자주하는질문
    path: '/faq',
    name: 'faq',
    meta: { layout : "layoutNoFooter", bodyclass: "", title: "WECARE"},
    component: () => import('@/views/FaqBoard.vue')
  },
  {
    //쇼핑 도움서비스
    path: '/serviceInfo',
    name: 'serviceInfo',
    meta: { layout : "layoutNoFooter", bodyclass: "", title: "WECARE"},
    component: () => import('@/views/ShoppingService.vue')
  },
  {
    //문의하기
    path: '/qna',
    name: 'qna',
    meta: { layout : "layoutNoFooter", bodyclass: "", title: "WECARE"},
    component: () => import('@/views/QnABoard.vue')
  },
  {
    //나의문의내역
    path: '/myQna',
    name: 'myQna',
    meta: { layout : "layoutNoFooter", bodyclass: "", title: "WECARE"},
    component: () => import('@/views/MyQna.vue')
  },
  {
    //문의글작성
    path: '/writeQna',
    name: '/writeQna',
    meta: {layout: "layoutNoFooter", bodyclass: "", title: "WECARE"},
    component: () => import('@/views/WriteQna.vue')
  },
  {
    // 마이페이지 - 의사
    path : '/myPageDoctor',
    name : 'myPageDoctor',
    meta : {layout : 'layoutNoFooter', bodyclass: '', title: 'WECARE'},
    component:()=> import('@/views/MyPageDoctor.vue')
  },
  {
    // 마이페이지 - 환자
    path : '/myPagePatient',
    name : 'myPagePatient',
    meta : {layout : 'layoutNoFooter', bodyclass: '', title: 'WECARE'},
    component:()=> import('@/views/MyPagePatient.vue')
  },
  {
    // 부가정보(수술정보) 입력
    path: '/surgeryInfo',
    name : 'surgeryInfo',
    meta : {layout : 'layoutNoFooter', bodyclass: '', title: 'WECARE'},
    component:()=> import('@/views/SurgeryInfo.vue')
  },
  {
    // 마이페이지 - 탈퇴
    path: '/deleteAccount',
    name : 'deleteAccount',
    meta : {layout : 'layoutNoFooter', bodyclass: '', title: 'WECARE'},
    component:()=> import('@/views/DeleteAccount.vue')
  },
  {
    // 실제 설문 수행
    path: '/deleteAccount',
    name : 'deleteAccount',
    meta : {layout : 'layoutNoFooter', bodyclass: '', title: 'WECARE'},
    component:()=> import('@/views/DeleteAccount.vue')
  },
  {
    // 실제 설문 수행
    path: '/surveyRealFinal',
    name: "surveyRealFinal",
    meta: { layout : "layoutNoFooter", bodyclass: "", title: "WECARE"},
    component : () => import('@/views/SurveyRealFinal.vue')
  },
  {
    // 설문 수행 - 자가진단
    path: '/surveyRealFinalSelf',
    name: "surveyRealFinalSelf",
    meta: { layout : "layoutNoFooter", bodyclass: "", title: "WECARE"},
    component : () => import('@/views/SurveyRealFinalSelf.vue')
  },
  {
    // 에러1 - 회원만 이용 가능
    path : '/userError',
    name : 'userError',
    meta : {layout: 'layoutNoFooter', bodyclass: '', title: 'WECARE'},
    component:() => import('@/views/userError.vue')
  },
  {
    // 에러2 - 부가정보 입력시에만 이용 가능
    path : '/infoError',
    name : "infoError",
    meta : {layout: 'layoutNoFooter', bodyclass: '', title: 'WECARE'},
    component:() => import('@/views/infoError.vue')
  },
  {
    // 404 에러 페이지
    path : '/errorPage404',
    name : 'errorPage404',
    meta : {layout: 'layoutNoFooter', bodyclass: '', title: 'WECARE'},
    component:() => import('@/views/ErrorPage404.vue')
  },
  {
    // 개인 정보 처리 방침
    path : '/privacyInfo',
    name : 'privacyInfo',
    meta : {layout: 'layoutNoFooter', bodyclass: '', title: 'WECARE'},
    component:() => import('@/views/privacyInfo.vue')
  },
  {
    // 서비스 이용약관
    path : '/termsInfo',
    name : 'termsInfo',
    meta : {layout: 'layoutNoFooter', bodyclass: '', title: 'WECARE'},
    component:() => import('@/views/termsInfo.vue')
  },
  {
    // KOQUSS-40 자가진단
    path: '/SurveySelf',
    name: "SurveySelf",
    meta: { layout : "layoutNoFooter", bodyclass: "", title: "WECARE"},
    component : () => import('@/views/SurveySelf.vue')
  },
  {
    // path 등록되어있지 않은 경우
    path : '/:catchAll(.*)',
    redirect : '/errorPage404'
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

/* const isLoggedIn = () => {
  return (localStorage.getItem('_lunit_token') === null) ? false: true
} */

// 라우터 이동 전 로그인 체크
router.beforeEach((to, from, next) => {

  let token = localStorage.getItem("_lunit_token");
  if(token != null) {
    sessionStorage.setItem("sessionLogin", 'true')
  }

  if (to.meta.bodyclass) {
    document.body.className = to.meta.bodyclass;
  } else {
    document.body.className = '';
  }
  next();
});

// 라우터 이동 후
router.afterEach((to)=>{
  let title = to.meta.title === undefined || to.meta.title === '' ? "WECARE" : to.meta.title
  document.title = title
})

export default router
