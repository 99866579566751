/**
 * api 공통
 */
import NetworkService from "@/api/net.service";

const NetApiServer = new NetworkService("")

/**
 * 쇼핑 api
 * @type {{getSidoList(): Promise<AxiosResponse<any>>}}
 */
export const ApiShopping = {

  /**
   * 쇼핑 목록 가져오기
   * @returns {Promise<AxiosResponse<any>>}
   */
  getShopList(){
    return NetApiServer.get("/api/shopping/list").then(res => {
      return res
    })
  },

  sortList() {
    return NetApiServer.post("/api/shopping/sortList").then(res =>{
      return res
    })
  }
}
