/**
 * api 공통
 */
import NetworkService from "@/api/net.service";

const NetApiServer = new NetworkService("")

/**
 * 설문 api
 * @type {{getSidoList(): Promise<AxiosResponse<any>>}}
 */
export const ApiSurvey = {

    /**
     * 설문 목록.
     * @returns {Promise<AxiosResponse<any>>}
     */
    getSurveyList(params){
       return NetApiServer.post("/api/survey/list", params).then(res => {
            return res
       })
    },

  /**
   * 설문조사 목록 가져오기 (선택 옵션 전용)
   * @returns {Promise<AxiosResponse<any>>}
   */
  getSurveyOptionList(params){
    return NetApiServer.post("/api/survey/optionList", params).then(res => {
      return res
    })
  },

  /**
   * 설문 결과 목록.
   * @returns {Promise<AxiosResponse<any>>}
   */
  getSurveyResult(params){
    return NetApiServer.post("/api/survey/resultList", params).then(res => {
      return res
    })
  },

  /**
   * splid로 환자 정보 가져오기.
   * @returns {Promise<AxiosResponse<any>>}
   */
  selectSurveyPatientInfo(params){
    return NetApiServer.post("/api/survey/patientInfoBySplid", params).then(res => {
      return res
    })
  },

  /**
   * 그룹 설문 목록.
   * @returns {Promise<AxiosResponse<any>>}
   */
  getSurveyGrpList(params){
    return NetApiServer.post("/api/survey/grpList", params).then(res => {
      return res
    })
  },

  /**
   * 설문조사 작성자 목록.
   * @returns {Promise<AxiosResponse<any>>}
   */
  getSurveyAdminList(params){
    return NetApiServer.post("/api/survey/adminList", params).then(res => {
      return res
    })
  },

  /**
   * 설문조사 항목과 옵션들 JSON 구조에 맞게 가져오기
   * @returns {Promise<AxiosResponse<any>>}
   */
  SurveyListDetail(params){
    return NetApiServer.post("/api/survey/list/detail", params).then(res => {
      return res
    })
  },

  /**
   * 설문조사 항목과 옵션들 JSON 구조에 맞게 가져오기
   * @returns {Promise<AxiosResponse<any>>}
   */
  SurveyListReal(params){
    return NetApiServer.post("/api/survey/list/real", params).then(res => {
      return res
    })
  },

  /**
   * 설문조사 항목과 옵션들 JSON 구조에 맞게 가져오기
   * @returns {Promise<AxiosResponse<any>>}
   */
  SurveyListRealFinal(params){
    return NetApiServer.post("/api/survey/list/realFinal", params).then(res => {
      return res
    })
  },

  /**
   * 설문조사 결과 항목과 옵션들 JSON 구조에 맞게 가져오기 (개별 설문)
   * @returns {Promise<AxiosResponse<any>>}
   */
  SurveyListResult(params){
    return NetApiServer.post("/api/survey/list/result", params).then(res => {
      return res
    })
  },

  /**
   * 설문조사 등록 및 수정
   * @returns {Promise<AxiosResponse<any>>}
   */
  insUpdSurveyList(params, files){
    return NetApiServer.multipart("/api/survey/insUpdMulti", params, files).then(res => {
      return res
    })
  },

  /**
   * 설문 그룹 등록 및 수정
   * @returns {Promise<AxiosResponse<any>>}
   */
  insUpdSurveyGrp(params){
    return NetApiServer.post("/api/survey/insUpdSurveyGrp", params).then(res => {
      return res
    })
  },

  /**
   * 설문 그룹 삭제
   * @returns {Promise<AxiosResponse<any>>}
   */
  delSurveyGrp(params){
    return NetApiServer.post("/api/survey/delSurveyGrp", params).then(res => {
      return res
    })
  },

  /**
   * 설문조사 삭제
   * @returns {Promise<AxiosResponse<any>>}
   */
  delSurvey(params){
    return NetApiServer.post("/api/survey/del", params).then(res => {
      return res
    })
  },

  /**
   * 환자 알림 전송
   * @returns {Promise<AxiosResponse<any>>}
   */
  linkedSurvey(params) {
    return NetApiServer.post("/api/survey/linkedSurvey", params).then(res => {
      return res
    })
  },

  /**
   * 환자 알림 전송 한명
   * @returns {Promise<AxiosResponse<any>>}
   */
  linkedSurveyOne(params) {
    return NetApiServer.post("/api/survey/linkedSurveyOne", params).then(res => {
      return res
    })
  },

  /**
   * 설문수행 목록 가져오기
   * @returns {Promise<AxiosResponse<any>>}
   */
  getSurveyPerformPage(params){
    return NetApiServer.post("/api/survey/PerformPage", params).then(res => {
      return res
    })
  },

  /**
   * 설문수행 목록 가져오기
   * @returns {Promise<AxiosResponse<any>>}
   */
  getSurveyPage(params){
    return NetApiServer.post("/api/survey/surveyPage", params).then(res => {
      return res
    })
  },

  /**
   * 설문수행 전체 목록 가져오기
   * @returns {Promise<AxiosResponse<any>>}
   */
  selectPerformPage(params){
    return NetApiServer.post("/api/survey/selectPerformPage", params).then(res => {
      return res
    })
  },

  /**
   * 설문수행 답변 맵으로 저장
   * @returns {Promise<AxiosResponse<any>>}
   */
  insUpdSurveyAnswerList(params){
    return NetApiServer.post("/api/survey/insUpdSurveyAnswerList", params).then(res => {
      return res
    })
  },

  /**
   * 설문수행 답변 저장
   * @returns {Promise<AxiosResponse<any>>}
   */
  insUpdSurveyAnswer(params){
    return NetApiServer.post("/api/survey/insUpdSurveyAnswer", params).then(res => {
      return res
    })
  },

  /**
   * 설문수행 답변 현황
   * @returns {Promise<AxiosResponse<any>>}
   */
  selectAnswerList(params){
    return NetApiServer.post("/api/survey/selectAnswerList", params).then(res => {
      return res
    })
  },

  /**
   * 설문수행 특정 답변 하나 가져오기
   * @returns {Promise<AxiosResponse<any>>}
   */
  selectAnswer(params){
    return NetApiServer.post("/api/survey/selectAnswer", params).then(res => {
      return res
    })
  },

  /**
   * 설문수행 특정 답변 하나 가져오기
   * @returns {Promise<AxiosResponse<any>>}
   */
  selectAnsweredList(params){
    return NetApiServer.post("/api/survey/selectAnsweredList", params).then(res => {
      return res
    })
  },

  /**
   * 설문수행 해당 설문 모든 답변 여부 확인
   * @returns {Promise<AxiosResponse<any>>}
   */
  selectAnsweredAll(params){
    return NetApiServer.post("/api/survey/selectAnsweredAll", params).then(res => {
      return res
    })
  },

  /**
   * 설문수행 미답변 설문 확인
   * @returns {Promise<AxiosResponse<any>>}
   */
  selectNoAnsweredList(params){
    return NetApiServer.post("/api/survey/selectNoAnsweredList", params).then(res => {
      return res
    })
  },

  /**
   * 설문수행 완료
   * @returns {Promise<AxiosResponse<any>>}
   */
  insSurveyFinish(params){
    return NetApiServer.post("/api/survey/insSurveyFinish", params).then(res => {
      return res
    })
  },

  /**
   * KOQUSS 점수 조회
   * @returns {Promise<AxiosResponse<any>>}
   */
  selKoqussScoreOne(params){
    return NetApiServer.post("/api/survey/selKoqussScoreOne", params).then(res => {
      return res
    })
  },

  /**
   * KOQUSS 점수 통계
   * @returns {Promise<AxiosResponse<any>>}
   */
  selKoqussScoreStatistics(params){
    return NetApiServer.post("/api/survey/selKoqussScoreStatistics", params).then(res => {
      return res
    })
  },

  /**
   * NRS2002 점수 통계
   * @returns {Promise<AxiosResponse<any>>}
   */
  selNrsScoreStatistics(params){
    return NetApiServer.post("/api/survey/selNrsScoreStatistics", params).then(res => {
      return res
    })
  },

  /**
   * 설문 코멘트 업데이트
   * @returns {Promise<AxiosResponse<any>>}
   */
  updComment(params){
    return NetApiServer.post("/api/survey/updComment", params).then(res => {
      return res
    })
  },

  /**
   * 설문 코멘트 가져오기
   * @returns {Promise<AxiosResponse<any>>}
   */
  selComment(params){
    return NetApiServer.post("/api/survey/selComment", params).then(res => {
      return res
    })
  },

  /**
   * 코쿠스 점수 값 저장
   * @returns {Promise<AxiosResponse<any>>}
   */
  insKoqussScore(params){
    return NetApiServer.post("/api/survey/insKoqussScore", params).then(res => {
      return res
    })
  },

  /**
   * 코쿠스 점수 값 수정
   * @returns {Promise<AxiosResponse<any>>}
   */
  updKoqussScore(params){
    return NetApiServer.post("/api/survey/updKoqussScore", params).then(res => {
      return res
    })
  },

  /**
   * 설문 코멘트 프리셋 리스트 가져오기
   * @returns {Promise<AxiosResponse<any>>}
   */
  selSurveyCommentPresetList(params){
    return NetApiServer.post("/api/survey/selSurveyCommentPresetList", params).then(res => {
      return res
    })
  },

  /**
   * 특정 설문 코멘트 프리셋 가져오기
   * @returns {Promise<AxiosResponse<any>>}
   */
  selSurveyCommentPresetOne(params){
    return NetApiServer.post("/api/survey/selSurveyCommentPresetOne", params).then(res => {
      return res
    })
  },

  /**
   * 설문 코멘트 프리셋 저장/수정
   * @returns {Promise<AxiosResponse<any>>}
   */
  insUpdSurveyCommentPreset(params){
    return NetApiServer.post("/api/survey/insUpdSurveyCommentPreset", params).then(res => {
      return res
    })
  },

  /**
   * 설문 코멘트 프리셋 삭제
   * @returns {Promise<AxiosResponse<any>>}
   */
  delSurveyCommentPreset(params){
    return NetApiServer.post("/api/survey/delSurveyCommentPreset", params).then(res => {
      return res
    })
  },

  /**
   * 환자 번호로 survey_finish 테이블 koquss-40 정보 가져오기
   * @returns {Promise<AxiosResponse<any>>}
   */
  selKoqussByPetiSeq(params){
    return NetApiServer.post("/api/survey/selKoqussByPetiSeq", params).then(res => {
      return res
    })
  },
}
