const adminInfoStore = {
  state: {
    token: '',    // token
    loginId: '',  // 로그인 id
    loginName: '',  // 로그인 name
    isLoggedInAdmin: false, // 로그인 유지 true, false
    adminInfo: {}
  },
  getters: {
    getAdminInfo (state) {
      return state.adminInfo
    },
    getToken (state) {
      return state.token
    },
    //로그인정보(아이디)
    getLoginId (state) {
      return state.loginId
    },
    //로그인정보(이름)
    getLoginNm (state) {
      return state.loginName
    },
    //로그인유지
    getIsLoggedInAdmin (state) {
      return state.isLoggedInAdmin
    }
  },
  mutations: {
    setToken (state, param) {
      state.token = param
    },
    setUseTypes (state, param) {
      state.useTypes = param
    },
    setAdminInfo (state, param) {
      state.adminInfo = param
    },
    setIsLoggedInAdmin (state, param) {
      state.isLoggedInAdmin = param
    },
    // 공통 alert 에서 사용
    openAlertDialog (state, payload) {
      state.alertDialogInfo = payload
      state.alertDialogToggle = true
    },
    closeAlertDialog (state) {
      //state.alertDialogInfo =
      state.alertDialogToggle = false
    }
  },
  actions: {
    // 공통 alert
    async openAlertDialog ({commit}, alertDialogInfo) {
      commit("openAlertDialog", alertDialogInfo)
    },
    async closeAlertDialog ({commit}) {
      commit("closeAlertDialog")
    }
  },
}
export default adminInfoStore
