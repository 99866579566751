const userInfoStore = {
  state: {
    token: '', // token
    loginId: '', // 로그인 id
    loginName: '', // 로그인 name
    isLoggedIn : false, // 로그인 유지 true, false
    userInfo : {}
  },
  getters : {
    getUserInfo(state) {
      return state.userInfo
    },
    getToken(state) {
      return state.token
    },
    // 로그인 정보(아이디)
    getLoginId(state) {
      return state.loginId
    },
    // 로그인정보(이름)
    getLoginName (state) {
      return state.loginName;
    },
    // 로그인 유지
    getIsLoggedIn (state) {
      return state.isLoggedIn;
    }
  },
  mutations : {
    setToken (state, param) {
      state.token = param
    },
    setUserInfo(state, param) {
      state.userInfo = param
    },
    setIsLoggedIn (state, param) {
      state.isLoggedIn = param
    }
  }
}
export default userInfoStore