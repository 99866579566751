<script setup>

import '@/assets/css/common.css'
import '@/assets/css/survey.css'
import '@/assets/css/noto-sans-kr.css'

import {computed, provide} from "vue";

import { useRouter } from "vue-router";
import layoutFull from "@/views/layout/layoutFull.vue";
import layoutNoFooter from "@/views/layout/layoutNoFooter.vue";

const router = useRouter()

// router 에서 설정된 레이아웃 조회
const layout = computed(() => {

  let layout = router.currentRoute.value.meta.layout
  if(layout === undefined){
    layout = "layoutContents"
  }
  console.log("-=-------------------------");
  console.log("layout: %s", router.currentRoute.value.meta.layout);
  console.log("bodyClass: %s", router.currentRoute.value.meta.bodyclass);

  return layout
})

</script>


<template>

  <layout-no-footer v-if="layout === 'layoutNoFooter'"></layout-no-footer>

  <layout-full v-if="layout === 'layoutFull'"></layout-full>

</template>

<style>

</style>
