
const newTabParamsStore = {
  state : {
    params : {
      'patiSeq' : '',
      'surveyCycleList' : []
    }
  },
  getters : {
    getParams(state) {
      return state.params
    }
  },
  mutations: {
    setParams(state, param){
      state.params = param
    }
  },
  actions: {

  }
}
export default newTabParamsStore
