<script setup>

import {computed, onMounted, ref, watch} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";

const isSearchButtonOn = ref(false)
const languageButtonOn = ref(false)
const gnbOpen = ref(false)
const route = useRoute();
const store = useStore()

// 우측상단 로그아웃, 메뉴버튼 toggle 변수
const searchBarClick = () => {
  // toggle isButtonOn
  isSearchButtonOn.value = !isSearchButtonOn.value
  console.log('searchBarClick');
}

const btnLanguageClick = () => {
  languageButtonOn.value= !languageButtonOn.value
}

const isActiveEqual = (path) => {
  // 현재 라우트에 path 문자열이 일치하면 true
  return route.path === (path);
};
const isActiveInculdes = (path)=>{
  return route.path.includes(path);
}

// 사용자정보
const userInfo = computed(()=> store.getters.getUserInfo)
const adminInfo = computed(()=> store.getters.getAdminInfo)

let sessionLogin = sessionStorage.getItem('sessionLogin')

onMounted(()=>{

  // 다른 탭에서 로그아웃 했을 경우
  if(Object.keys(userInfo.value).length == 0 && Object.keys(adminInfo.value).length == 0) {
    sessionStorage.clear()
  }

})

watch(route, (newRoute, oldRoute) => {
  sessionLogin = sessionStorage.getItem('sessionLogin')
});

</script>

<template>
  <div class="header">
    <div class="header_inner">
      <h1>
<!--        <router-link to="/"><span>Lunit</span> Service</router-link>-->
        <router-link to="/"><img src="@/assets/img/wecare_length.png" style="height: 4rem;"></router-link>
        <!-- GNB -->

      </h1>
      <div class="gnb_area"  :class="{'is-active': gnbOpen}">
        <div class="gnb_inner">
          <div class="gnb_top mo">
            <a href="#" class="gnb_close" @click="gnbOpen = false"><span class="blind">닫기</span></a>
<!--            <h2><router-link to="/"><span>Lunit</span> Service</router-link></h2>-->
            <router-link to="/"><img src="@/assets/img/wecare_length.png" style="height: 4rem;"></router-link>

          </div>

          <ul class="gnb">
            <li :class="isActiveEqual('/content') || isActiveInculdes('/detail') ? 'on' : ''"><router-link to="/content" @click="gnbOpen = false">암 콘텐츠</router-link></li>
            <li :class="isActiveEqual('/survey') || isActiveEqual('/surveyStart') ? 'on' : ''" v-if="Object.keys(adminInfo).length == 0"><router-link to="/survey" @click="gnbOpen = false">설문하기</router-link></li>
            <li :class="isActiveEqual('/surveyResult') ? 'on' : ''" v-if="Object.keys(adminInfo).length == 0"><router-link to="/surveyResult" @click="gnbOpen = false">설문결과</router-link></li>
            <li :class="isActiveInculdes('/faq') ? 'on' : ''"><router-link to="/faq" @click="gnbOpen = false">자주하는 질문</router-link></li>
            <li :class="isActiveInculdes('/serviceInfo') ? 'on' : ''"><router-link to="/serviceInfo" @click="gnbOpen = false">쇼핑‧도움서비스</router-link></li>
            <li :class="isActiveInculdes('/qna') || isActiveInculdes('/myQna') || isActiveInculdes('/writeQna')? 'on' : ''"><router-link to="/qna" @click="gnbOpen = false">문의하기</router-link></li>
            <li :class="isActiveEqual('/login') || isActiveInculdes('/join') || isActiveInculdes('/idPwInquiry') || isActiveInculdes('/agreeTerm')? 'on' : ''" v-show="sessionLogin == null || sessionLogin == undefined ||(Object.keys(userInfo).length == 0 && Object.keys(adminInfo).length == 0)"><router-link to="/login" @click="gnbOpen = false">로그인/가입하기</router-link></li>
          </ul>
        </div>
      </div>
      <!-- //GNB -->
    <!-- display:none 설정 된 부분들 로그인 했을 시 보여야 함! -->
      <div class="top_links">
        <div class="language_area pc" style="display: none;">
          <button class="btn_language" :class="{'on': languageButtonOn}" @click = "btnLanguageClick"
                  data-lang="kor"
                  title="언어선택"><span class="blind">언어선택</span></button>
          <ul class="layer_language" :class="{'active': languageButtonOn}">
            <li><button class="btn_language" data-lang="kor"></button></li>
            <li><button class="btn_language" data-lang="eng"></button></li>
            <li><button class="btn_language" data-lang="jpn"></button></li>
            <li><button class="btn_language" data-lang="chn"></button></li>
          </ul>
        </div>
        <div class="search_area"  style="display: none;">
          <button class="btn_search"
                  :class="{'on': isSearchButtonOn}"
                  @click="searchBarClick" title="검색"><span class="blind">검색</span></button>
          <div class="layer_search"
               :class="{'active': isSearchButtonOn}"
          >
            <div class="search_inner">
              <div class="search_box">
                <div class="input_wrap">
                  <input type="text" placeholder="검색어를 입력하세요.">
                  <button class="btn_round">검색</button>
                </div>
                <div class="recommend">
                  <strong class="tit">추천검색어</strong>
                  <ul>
                    <li><a href="#">수술 후 합병증</a></li>
                    <li><a href="#">수술 후 관리</a></li>
                    <li><a href="#">운동법</a></li>
                    <li><a href="#">식단관리</a></li>
                  </ul>
                </div>

              </div>
            </div>
          </div>
        </div>
        <router-link to="/login" @click="gnbOpen = false" class="mo"><button v-show="Object.keys(userInfo).length == 0 && Object.keys(adminInfo).length == 0" class="btn_logout" style="display: none;"><span class="blind">로그아웃</span></button></router-link>
        <router-link to="/myPagePatient" @click="gnbOpen = false"><button v-show="Object.keys(userInfo).length != 0 && sessionLogin == 'true'" class="btn_logout" style="display: none;"><span class="blind">로그아웃</span></button></router-link>
        <router-link to="/myPageDoctor" @click="gnbOpen = false"><button v-show="Object.keys(adminInfo).length != 0 && sessionLogin == 'true'" class="btn_logout" style="display: none;"><span class="blind">로그아웃</span></button></router-link>
        <button class="btn_menu mo" @click="gnbOpen = true" style="margin-left: 1rem;"><span class="blind">메뉴</span></button>
      </div>

    </div>
  </div>
  <!-- //header -->
</template>

<style scoped>

</style>
